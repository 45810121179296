<script setup lang="ts">
import type { TheTabsProps } from "./TheTabs.props"

const emit = defineEmits<{ (e: "change", value: number): void }>()

const props = withDefaults(defineProps<TheTabsProps>(), {
  labelColor: "green",
  tabLayout: "between",
  isLabelUppercase: false,
  isPadded: false,
  hideBottomBorder: false,
  isCustomTab: false,
  defaultIndex: 0,
  selectedIndex: 0
})

const defaultTab = ref(props.defaultIndex ?? 0)
const selectedTab = ref(props.selectedIndex ?? 0)
const handleChangeTab = (index: number) => {
  selectedTab.value = index
  emit("change", index)
}

watch(
  () => props.selectedIndex,
  (val) => {
    selectedTab.value = val
  },
  {
    immediate: true
  }
)
</script>

<template>
  <div>
    <TabGroup
      @change="handleChangeTab"
      :defaultIndex="defaultTab"
      :selectedIndex="selectedTab"
    >
      <TabList
        class="
          the_tabs-labels
          flex
          select-none
          gap-x-1
          overflow-x-auto
          whitespace-nowrap
          border-b border-black-10
          scrollbar-hide
          md:gap-x-6
        "
        :class="[
          {
            padded: isPadded,
            '!border-b-0': hideBottomBorder
          },
          `justify-${tabLayout}`
        ]"
      >
        <Tab
          v-for="(tab, i) in tabsList"
          :key="`${tab.id ? tab.id : tab.text}_${i}`"
          as="template"
          v-slot="{ selected }"
        >
          <button
            class="
              mouse
              md:pig
              tab
              relative
              flex-1
              p-2
              text-black-main
              outline-none
              md:flex-initial
              md:px-3
            "
            :tabindex="i"
            :class="[
              {
                uppercase: isLabelUppercase,
                'font-bold text-green-main': selected && labelColor === 'green',
                'font-bold text-orange-main ':
                  selected && labelColor === 'orange'
              },
              labelColor === 'orange'
                ? 'hover:text-orange-main'
                : 'hover:text-green-main'
            ]"
          >
            {{ tab?.text }}
            <span
              v-show="!selected"
              class="
                hover-border
                absolute
                bottom-0
                left-0
                right-0
                hidden
                h-[2px]
                rounded-md
              "
              :class="
                labelColor === 'orange' ? 'bg-orange-main' : 'bg-green-main'
              "
            />
            <span
              v-show="selected"
              class="
                absolute
                bottom-0
                left-0
                right-0
                h-[3px]
                rounded-full
                md:h-[4px]
              "
              :class="
                labelColor === 'orange' ? 'bg-orange-main' : 'bg-green-main'
              "
            />
          </button>
        </Tab>
      </TabList>
      <div>
        <slot name="tabs" :selectedTab="selectedTab" />
      </div>
    </TabGroup>
  </div>
</template>

<style lang="scss" scoped>

.tab:hover {
  .hover-border {
    display: block;
  }
}

</style>
